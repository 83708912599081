<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('districts.plans.view')">
          <actions
            slot="actions"
            crud-links="districtPlans"
            controller="DistrictPlans"
            delete-route="district-plans"

            :actions="actions"
            :action-data="{id: $route.params.id}"
          >
            <template v-if="planification.id != null">
              <va-popover
                :message="$t('tables.actions.export', {format: 'Excel'})"
                placement="down"
              >
                <va-button
                  flat
                  small
                  color="success"
                  icon="fa fa-file-excel-o"
                  @click.prevent="prepareDownload('xlsx')"
                />
              </va-popover>
            </template>
          </actions>
          <text-list
            v-if="planification.district"
            :condition="currentUser.can('Districts', 'view')"
            :title="$t('tables.headings.district')"
            :label="$t(planification.district.name)"
            :to="{ name: 'districtsView', params: {id: planification.district.id}}"
          />
          <text-list :title="$t('tables.headings.year')">
            {{ planification.year }}
          </text-list>
          <text-list :title="$t('tables.headings.district_manager')">
            {{ planification.manager_name }}
          </text-list>
          <text-list :title="$t('tables.headings.district_manager_email')">
            {{ planification.manager_email }}
          </text-list>
          <text-list
            v-show="planification.created_at"
            :title="$t('tables.headings.created_at')"
          >
            {{ planification.created_at | date }}
          </text-list>
          <text-list
            v-if="planification.submitter"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.submitter')"
            :label="planification.submitter.name"
            :to="{ name: 'usersView', params: {id: planification.submitter.id}}"
          />
        </va-card>
      </div>

      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('layout.preview') }}</span>
          <template slot="body">
            <form-builder :loading="loading" :questions="questions" :view-only="true" />
          </template>
        </va-collapse>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')
const FormBuilder = () => import(/* webpackPrefetch: true */ '@/components/extras/FormBuilder')

export default {
  name: 'district-plans-view',
  components: {
    Actions,
    TextList,
    FormBuilder,
  },
  data () {
    return {
      error: false,
      questions: [],
      planification: {},
      loading: false,
      actions: ['index', 'new', 'edit', 'delete'],
      planificationYear: (new Date()).getFullYear(),
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    nextYear () {
      return Number(this.planificationYear) + 1
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `district-plans/${id}`
    },
    fixData (planification) {
      if (!planification.annual_answers) return planification

      const definition = JSON.parse(planification.annual_answers)
      if (definition.length) {
        for (const d of definition) {
          if (!d.visible) {
            d.visible = { id: 0, key: -1, label: this.$t('forms.visibility.always') }
          }
        }
        this.questions = definition
      }
      return planification
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const planificationId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(planificationId))
      } catch (err) {
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.planification = this.fixData(u.data.data)
      this.planificationYear = this.planification.year
    },
    async prepareDownload (format) {
      const prepareRoute = this.routeBuilder(this.planification.id) + '?export=' + format
      const downloadRoute = 'district-plans/download/'

      return this.downloadFile(prepareRoute, downloadRoute)
    },
    async downloadFile (prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.get(prepareRoute)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
